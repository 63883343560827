import domReady from '@roots/sage/client/dom-ready';
import Swiper, { Navigation, Pagination} from 'swiper';
import counterUp from 'counterup2';
import HandyCollapse from "handy-collapse";


/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  const body = document.querySelector('body');
  const headerMenu = document.querySelector('header.banner');
  const burguer = document.querySelector('.burguer');
  const swiperDistrCamion = document.querySelector('.section-distr-camion-cat .swiper');
  const swiperRelated = document.querySelector('.swiper-related_trucks.swiper');
  const filters_mobile = document.querySelector('.filters-mobile');
  const filters_wrapper = document.querySelector('.filters_wrapper');
  
  const filters_close = document.querySelector('.section-filter-trucks .close');
  const filters_search = document.querySelector('.section-filter-trucks .search');
if(filters_mobile){
  filters_mobile.addEventListener('click', function(){
    filters_wrapper.style.right= '0px';
  });
  }

if(filters_close){
filters_close.addEventListener('click', function(){
  filters_wrapper.style.right= '-100%';
});
}

if(filters_search){
filters_search.addEventListener('click', function(){
  filters_wrapper.style.right= '-100%';
});
}
 new HandyCollapse({
  nameSpace: "hc",
});


new Swiper('.section-distr-camion-pro .swiper', {
  modules: [Navigation],
  slidesPerView: 'auto',
  spaceBetween: 20,
  navigation: {
    nextEl: '.section-distr-camion-pro .swiper-navigation-next',
    prevEl: '.section-distr-camion-pro .swiper-navigation-prev',
  },
})

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const type = urlParams.get('val')
var mySelect = document.getElementById('input_1_3');
if(type) mySelect.value = type;


  var swiperVertBig = new Swiper ('.swiper-container', {
    direction: 'vertical',
    modules : [Pagination, Navigation],
    effect: 'slide',
    
    slidesPerView: 1,
    spaceBetween:40,
    pagination: {
      el: '.swiper-pagination',
            clickable: true,
        renderBullet: function (index, className) {
          return '<img class="' + className + '" src="'+ document.querySelectorAll('.swiper-slide')[index].dataset.imgSrc+'">';
        },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
})


var swiperVertPreview = new Swiper ('.swiper-container-preview', {
  direction: 'vertical',
  slidesPerView: 1,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  on: {
    init: function () {
      // Deshabilitar el movimiento del swiper al hacer clic en un elemento
      this.slides.on('click', function (e) {
        e.stopPropagation();
      });
    },
  },
});

  if(headerMenu.classList.contains('home')){
    window.addEventListener('scroll', function(){
      var scrollPosition = window.pageYOffset;
  
      if (scrollPosition === 0) {
       headerMenu.classList.remove('active');
      } else {
        headerMenu.classList.add('active');
      }
  
    });
  }

  burguer ? burguer.addEventListener('click', showMenuMobile) : '';

  function showMenuMobile() {
    burguer.classList.toggle('active');
    headerMenu.classList.toggle('open');
    body.classList.toggle('open-menu');
  }

  if(swiperDistrCamion){
    new Swiper(swiperDistrCamion, {
        modules: [Navigation],
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
          nextEl: '.section-distr-camion-cat .swiper-navigation-next',
          prevEl: '.section-distr-camion-cat .swiper-navigation-prev',
        },
    })
  }
  if(swiperRelated){
    new Swiper(swiperRelated, {
        modules: [Navigation],
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
          nextEl: '.section_related_trucks .swiper-navigation-next',
          prevEl: '.section_related_trucks .swiper-navigation-prev',
        },
    })
  }
    
  const counterup = document.querySelectorAll('.counterup');

  counterup.forEach(element => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          counterUp( entry.target, {
            duration: 2000,
            delay: 16,
          } )
        }
      });
    });
  
    observer.observe(element);
  });

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
